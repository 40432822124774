import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CustomizedSnackbars from '../../Notifications/SnackBar';
import {
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Cancel as CancelIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  ReadMore as ReadMoreIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import ProvinciaAutocomplete from '../../Common/ProvinciaAutocomplete';
import { LoadingButton } from '@mui/lab';
import DepartamentoAutocomplete from '../../Common/DepartamentoAutocomplete';
import LocalidadSimpleAutocomplete from '../../Common/LocalidadSimpleAutocomplete';
import CalleAutocomplete from '../../Common/CalleAutocomplete';
import { constantes } from '../../../services/Common/Domicilio';
import { useDomicilio } from './hooks/useDomicilio';

const DomicilioEditForm = ({ infoAfiliado = null, onSaveChanges = null }) => {
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const { data: dataInit, save, message, clearMessage } = useDomicilio(infoAfiliado);

  const {
    control,
    formState: { errors, isDirty, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
    values: dataInit,
  });

  const handlerGuardarSubmit = async (data) => {
    if (!isDirty || !isValid) { return; }

    const result = await save(data);
    if (onSaveChanges) {
      onSaveChanges(result);
    }
  };

  const handleClickOpenMoreOptions = () => {
    setOpenMoreOptions(!openMoreOptions);
  };

  const clearFrom = (fromKey) => {
    const clearOrder = ['provincia', 'departamento', 'localidad', 'calle'];
    const startClearFrom = clearOrder.findIndex((item) => item === fromKey);

    for (var i = startClearFrom + 1; i < clearOrder.length; i++) {
      setValue(`${clearOrder[i]}`, null, { shouldValidate: false });
    }
  };

  const handlerSelectChange = ({ selectName, value }) => {
    const currentValue = getValues(selectName);
    if (!value || currentValue?.descripcion !== value?.descripcion) clearFrom(selectName);
    setValue(selectName, value, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <>
      <Grid>
        <form onSubmit={handleSubmit((data) => handlerGuardarSubmit(data))}>
          <Grid container spacing={2} rowSpacing={1}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth={true} variant='outlined'>
                <ProvinciaAutocomplete
                  value={getValues('provincia') || null}
                  onChangeOption={(e) => handlerSelectChange({ selectName: 'provincia', value: e })}
                  {...register('provincia', {
                    validate: (value) => !!value || 'Debe seleccionar la provincia',
                  })}
                  error={errors.provincia}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth={true} variant='outlined'>
                <DepartamentoAutocomplete
                  value={getValues('departamento') || null}
                  provinciaId={getValues('provincia')?.idProvincia || 0}
                  onChangeOption={(e) =>
                    handlerSelectChange({ selectName: 'departamento', value: e })
                  }
                  {...register('departamento', {
                    validate: (value) => !!value || 'Debe seleccionar el departamento',
                  })}
                  error={errors.departamento}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth={true} variant='outlined'>
                <LocalidadSimpleAutocomplete
                  value={getValues('localidad') || null}
                  departamentoId={getValues('departamento')?.idDepartamento || 0}
                  onChangeOption={(e) => handlerSelectChange({ selectName: 'localidad', value: e })}
                  {...register('localidad', {
                    validate: (value) => !!value || 'Debe seleccionar la localidad',
                  })}
                  error={errors.localidad}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth={true} variant='outlined'>
                <CalleAutocomplete
                  value={getValues('calle') || null}
                  localidadId={getValues('localidad')?.idLocalidad || 0}
                  onChangeOption={(e) => handlerSelectChange({ selectName: 'calle', value: e })}
                  {...register('calle', {
                    validate: (value) => !!value || 'Debe seleccionar la calle',
                  })}
                  error={errors.calle}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth={true} variant='outlined'>
                <Controller
                  control={control}
                  name='itemsDomicilioPersonaFisica.numero'
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        {...field}
                        label='Número'
                        variant='standard'
                        type='tel'
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                        fullWidth
                        value={field?.value?.valor || ''}
                        onChange={(e) => {
                          field.onChange({
                            ...field.value,
                            valor: e.target.value,
                            codigoTipoNumeracion: constantes.TIPO_NUMERACION_NUMERO,
                          });
                          trigger('itemsDomicilioPersonaFisica.numero');
                        }}
                        error={!!error}
                        helperText={error ? error.message : ''}
                      />
                    );
                  }}
                  rules={{
                    validate: {
                      pattern: (value) => {
                        const regex = /^[0-9]{0,4}$/;
                        return (
                          regex.test(value?.valor) ||
                          'Dato no válido. Ingrese solo números enteros, con un máximo de 4 dígitos'
                        );
                      },
                      required: (value) => {
                        const result =
                          !!value?.valor ||
                          'El número es requerido, si no tiene numeración, ingrese 0';
                        return result;
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth={true} variant='outlined'>
                <Controller
                  control={control}
                  name='itemsDomicilioPersonaFisica.piso'
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        label='Piso'
                        variant='standard'
                        type='tel'
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                        fullWidth
                        value={field?.value?.valor || ''}
                        onChange={(e) => {
                          field.onChange({
                            ...field.value,
                            valor: e.target.value,
                            codigoTipoNumeracion: constantes.TIPO_NUMERACION_PISO,
                          });
                          trigger('itemsDomicilioPersonaFisica.piso');
                        }}
                        error={!!error}
                        helperText={error ? error.message : ''}
                      />
                    );
                  }}
                  rules={{
                    validate: (value) => {
                      if (!value) { return true; }
                      const regex = /^[0-9]{0,2}$/;
                      return (
                        regex.test(value?.valor) ||
                        'Dato no válido. Ingrese solo números enteros, con un máximo de 2 caracteres'
                      );
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth={true} variant='outlined'>
                <Controller
                  control={control}
                  name='itemsDomicilioPersonaFisica.depto'
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        label='Depto.'
                        variant='standard'
                        fullWidth
                        value={field?.value?.valor || ''}
                        onChange={(e) => {
                          field.onChange({
                            ...field.value,
                            valor: e.target.value,
                            codigoTipoNumeracion: constantes.TIPO_NUMERACION_DEPTO,
                          });
                          trigger('itemsDomicilioPersonaFisica.depto');
                        }}
                        error={!!error}
                        helperText={error ? error.message : ''}
                      />
                    );
                  }}
                  rules={{
                    validate: (value) => {
                      if (!value) { return true; }
                      const regex = /^[a-zA-Z0-9]{0,4}$/;
                      return (
                        regex.test(value?.valor) ||
                        'Dato no válido. Ingrese solo letras mayúsculas/minúsculas y/o números enteros, con un máximo de 4 caracteres'
                      );
                    },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <List sx={{ width: '100%', p: 0 }} aria-labelledby='nested-list-subheader'>
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <ListItemButton onClick={() => handleClickOpenMoreOptions()}>
                <ListItemIcon>
                  <ReadMoreIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'bold' }} style={{ color: '#000000' }}>
                      Más opciones
                    </Typography>
                  }
                />
                {openMoreOptions ? (
                  <ExpandLessIcon color='primary' />
                ) : (
                  <ExpandMoreIcon color='primary' />
                )}
              </ListItemButton>
            </FormControl>
          </List>

          <Collapse in={openMoreOptions} timeout='auto' unmountOnExit>
            <Grid container spacing={2} rowSpacing={1}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth={true} variant='outlined'>
                  <Controller
                    control={control}
                    name='itemsDomicilioPersonaFisica.casa'
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          label='Casa'
                          variant='standard'
                          fullWidth
                          value={field?.value?.valor || ''}
                          onChange={(e) => {
                            field.onChange({
                              ...field.value,
                              valor: e.target.value,
                              codigoTipoNumeracion: constantes.TIPO_NUMERACION_CASA,
                            });
                            trigger('itemsDomicilioPersonaFisica.casa');
                          }}
                          error={!!error}
                          helperText={error ? error.message : ''}
                        />
                      );
                    }}
                    rules={{
                      validate: (value) => {
                        if (!value) { return true; }
                        const regex = /^[a-zA-Z0-9]{0,10}$/;
                        return (
                          regex.test(value?.valor) ||
                          'Dato no válido. Ingrese solo letras mayúsculas/minúsculas y/o números enteros, con un máximo de 10 caracteres'
                        );
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth={true} variant='outlined'>
                  <Controller
                    control={control}
                    name='itemsDomicilioPersonaFisica.manzana'
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          id='manzana'
                          label='Manzana'
                          variant='standard'
                          fullWidth
                          value={field?.value?.valor || ''}
                          onChange={(e) => {
                            field.onChange({
                              ...field.value,
                              valor: e.target.value,
                              codigoTipoNumeracion: constantes.TIPO_NUMERACION_MANZANA,
                            });
                            trigger('itemsDomicilioPersonaFisica.manzana');
                          }}
                          error={!!error}
                          helperText={error ? error.message : ''}
                        />
                      );
                    }}
                    rules={{
                      validate: (value) => {
                        if (!value) { return true; }
                        const regex = /^[a-zA-Z0-9]{0,10}$/;
                        return (
                          regex.test(value?.valor) ||
                          'Dato no válido. Ingrese solo letras mayúsculas/minúsculas y/o números enteros, con un máximo de 10 caracteres'
                        );
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth={true} variant='outlined'>
                  <Controller
                    control={control}
                    name='itemsDomicilioPersonaFisica.tira'
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          label='Tira'
                          variant='standard'
                          fullWidth
                          value={field?.value?.valor || ''}
                          onChange={(e) => {
                            field.onChange({
                              ...field.value,
                              valor: e.target.value,
                              codigoTipoNumeracion: constantes.TIPO_NUMERACION_TIRA,
                            });
                            trigger('itemsDomicilioPersonaFisica.tira');
                          }}
                          error={!!error}
                          helperText={error ? error.message : ''}
                        />
                      );
                    }}
                    rules={{
                      validate: (value) => {
                        if (!value) { return true; }
                        const regex = /^[a-zA-Z0-9]{0,10}$/;
                        return (
                          regex.test(value?.valor) ||
                          'Dato no válido. Ingrese solo letras mayúsculas/minúsculas y/o números enteros, con un máximo de 10 caracteres'
                        );
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth={true} variant='outlined'>
                  <Controller
                    control={control}
                    name='itemsDomicilioPersonaFisica.torre'
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          label='Torre'
                          variant='standard'
                          fullWidth
                          value={field?.value?.valor || ''}
                          onChange={(e) => {
                            field.onChange({
                              ...field.value,
                              valor: e.target.value,
                              codigoTipoNumeracion: constantes.TIPO_NUMERACION_TORRE,
                            });
                            trigger('itemsDomicilioPersonaFisica.torre');
                          }}
                          error={!!error}
                          helperText={error ? error.message : ''}
                        />
                      );
                    }}
                    rules={{
                      validate: (value) => {
                        if (!value) { return true; }
                        const regex = /^[a-zA-Z0-9]{0,10}$/;
                        return (
                          regex.test(value?.valor) ||
                          'Dato no válido. Ingrese solo letras mayúsculas/minúsculas y/o números enteros, con un máximo de 10 caracteres'
                        );
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth={true} variant='outlined'>
                  <Controller
                    control={control}
                    name='itemsDomicilioPersonaFisica.cuerpo'
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          label='Cuerpo'
                          variant='standard'
                          fullWidth
                          value={field?.value?.valor || ''}
                          onChange={(e) => {
                            field.onChange({
                              ...field.value,
                              valor: e.target.value,
                              codigoTipoNumeracion: constantes.TIPO_NUMERACION_CUERPO,
                            });
                            trigger('itemsDomicilioPersonaFisica.cuerpo');
                          }}
                          error={!!error}
                          helperText={error ? error.message : ''}
                        />
                      );
                    }}
                    rules={{
                      validate: (value) => {
                        if (!value) { return true; }
                        const regex = /^[a-zA-Z0-9]{0,10}$/;
                        return (
                          regex.test(value?.valor) ||
                          'Dato no válido. Ingrese solo letras mayúsculas/minúsculas y/o números enteros, con un máximo de 10 caracteres'
                        );
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth={true} variant='outlined'>
                  <Controller
                    control={control}
                    name='itemsDomicilioPersonaFisica.km'
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          label='Km'
                          variant='standard'
                          fullWidth
                          value={field?.value?.valor || ''}
                          onChange={(e) => {
                            field.onChange({
                              ...field.value,
                              valor: e.target.value,
                              codigoTipoNumeracion: constantes.TIPO_NUMERACION_KM,
                            });
                            trigger('itemsDomicilioPersonaFisica.km');
                          }}
                          error={!!error}
                          helperText={error ? error.message : ''}
                        />
                      );
                    }}
                    rules={{
                      validate: (value) => {
                        if (!value) { return true; }
                        const regex = /^[a-zA-Z0-9]{0,10}$/;
                        return (
                          regex.test(value?.valor) ||
                          'Dato no válido. Ingrese solo letras mayúsculas/minúsculas y/o números enteros, con un máximo de 10 caracteres'
                        );
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Collapse>

          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2,
                justifyContent: 'flex-end',
                py: 4,
                pr: { xs: 1, sm: 4 },
              }}
            >
              <Button
                variant='contained'
                color='neutral'
                size='medium'
                startIcon={<CancelIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  reset();
                }}
                disabled={!isDirty}
              >
                Cancelar
              </Button>
              <LoadingButton
                variant='contained'
                size='medium'
                color='primary'
                type='submit'
                startIcon={<SaveIcon />}
                disabled={!isDirty || !isValid}
              >
                Guardar cambios
              </LoadingButton>
            </Box>
          </Grid>
        </form>
      </Grid>

      <CustomizedSnackbars
        open={message.message}
        severity={message.status}
        message={message.message}
        onDeleteMessage={clearMessage}
      />
    </>
  );
};

export default DomicilioEditForm;
