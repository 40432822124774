import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import DomicilioEditForm from './DomicilioEditForm';
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { tipoNumeracion } from '../../../services/Common/Domicilio';

const DrawerDataField = ({ label, data }) => {
  return (
    <ListItem>
      <Grid container alignItems={'baseline'}>
        <Grid item xs={12} sm={4} md={3}>
          <ListItemText
            secondary={
              <Typography gutterBottom variant='body2' display='block' fontWeight={600}>
                {label}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <ListItemText primary={data} primaryTypographyProps={{ fontWeight: 'bold' }} />
        </Grid>
      </Grid>
    </ListItem>
  );
};

export const Domicilio = ({ infoAfiliado = null }) => {
  const [afiliado, setAfiliado] = useState(infoAfiliado);
  const [editarDomicilio, setEditarDomicilio] = useState(false);

  const itemsDomicilio = useMemo(() => {
    const result = {};
    afiliado?.itemsDomicilioPersonaFisica?.map((i) => {
      result[tipoNumeracion[i.codigoTipoNumeracion].name] = i;
    });

    return result;
  }, [afiliado]);

  const onHandleDataSaved = (e) => {
    if (e) {
      setAfiliado({
        ...afiliado,
        domicilio: e.domicilio,
        provincia: e.provincia,
        departamento: e.departamento,
        localidad: e.localidad,
        calle: e.calle,
        itemsDomicilioPersonaFisica: e.itemsDomicilioPersonaFisica,
      });
      setEditarDomicilio(false);
    }
  };

  const getAddress = () => {
    const otrosDetalles = [
      itemsDomicilio?.casa?.valor ? 'Casa - ' + itemsDomicilio?.casa?.valor : null,
      itemsDomicilio?.manzana?.valor ? 'Manz. - ' + itemsDomicilio?.manzana?.valor : null,
      itemsDomicilio?.cuerpo?.valor ? 'Cuerpo - ' + itemsDomicilio?.cuerpo?.valor : null,
      itemsDomicilio?.torre?.valor ? 'Torre - ' + itemsDomicilio?.torre?.valor : null,
      itemsDomicilio?.tira?.valor ? 'Tira - ' + itemsDomicilio?.tira?.valor : null,
      itemsDomicilio?.km?.valor ? 'Km. - ' + itemsDomicilio?.km?.valor : null,
    ].filter((item) => !!item);

    return (
      <>
        {afiliado?.calle && <>{afiliado?.calle?.descripcion}</>}{' '}
        {itemsDomicilio?.numero?.valor && <>{itemsDomicilio?.numero?.valor === '0' ? 'SN' : itemsDomicilio?.numero?.valor}</>}
        <br />
        {itemsDomicilio?.piso?.valor && (
          <>Piso: {itemsDomicilio?.piso?.valor} <br /></>
        )}
        {itemsDomicilio?.depto?.valor && (
          <>Departamento: {itemsDomicilio?.depto?.valor} <br /></>
        )}
        {otrosDetalles.length > 0 && (
          <>Otros datos: {otrosDetalles.join(', ')} <br /></>
        )}
        {afiliado?.localidad && <>{afiliado?.localidad?.descripcion}</>}
        <br />
        {afiliado?.provincia && <>{afiliado?.provincia?.descripcion}</>}
      </>
    );
  };

  return (
    <Grid item xs={12} lg={9}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          sx={{ paddingTop: 1, paddingBottom: 0 }}
          title={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>Domicilio</span>
              <Tooltip id='button-link' title='Editar Domicilio'>
                <Fab
                  size='small'
                  color='default'
                  aria-label='Editar Domicilio'
                  sx={{ boxShadow: 'none' }}
                  onClick={(e) => setEditarDomicilio(!editarDomicilio)}
                >
                  {!editarDomicilio && <EditIcon fontSize='small' color='primary' />}
                  {editarDomicilio && <CloseIcon fontSize='small' color='primary' />}
                </Fab>
              </Tooltip>
            </Box>
          }
        />
        <CardContent
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: 0,
            marginBottom: -2,
          }}
        >
          <List>
            {!editarDomicilio && <DrawerDataField label='Dirección Actual' data={getAddress()} />}

            {editarDomicilio && (
              <Box px={2}>
                <DomicilioEditForm
                  infoAfiliado={afiliado}
                  onSaveChanges={(e) => onHandleDataSaved(e)}
                />
              </Box>
            )}
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
};
