import { Controller, useForm } from 'react-hook-form';
import CustomizedSnackbars from '../../Notifications/SnackBar';
import { useContacto } from './hooks/useContacto';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ContactoField } from './ContactoField';
import {
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import TipoContactoAutocomplete from '../../Common/TipoContactoAutocomplete';
import { LoadingButton } from '@mui/lab';
import { ConfirmacionAccionModal } from './ConfirmacionAccionModal';
import { useState } from 'react';

export const ContactoEditForm = ({ contactos = null, afiliado = null, onSaveChanges = null }) => {
  const {
    clearMessage,
    contactosByTipo,
    deleteContacto,
    initDataForm,
    message,
    onChangeTipoContacto,
    onlyOneContacto,
    saveContacto,
    showMessage,
    validacionContacto,
  } = useContacto(contactos, afiliado);

  const {
    clearErrors,
    control,
    formState: { errors, isDirty, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setFocus,
    setValue,
  } = useForm({
    mode: 'onChange',
    values: initDataForm,
  });

  const [contactToDelete, setContactToDelete] = useState(null);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handlerGuardarSubmit = async (changedData = null) => {
    if (!isDirty || !isValid) { return; }

    const result = await saveContacto(changedData);
    if (result) {
      reset();
    }
    if (onSaveChanges) {
      onSaveChanges(result);
    }
  };

  const handleDeleteContacto = (contacto) => {
    setContactToDelete(contacto);
    setMessageToDelete(
      `¿Está seguro de que quiere eliminar el ${contacto?.descripcionTipoContacto?.toLowerCase()} ${
        contacto?.descripcion
      }?`,
    );
    setOpenDeleteDialog(true);
  };

  const handleDeleteContactoClose = (response) => {
    setOpenDeleteDialog(false);
    if (response) {
      handlerDeleteSubmit(contactToDelete);
    }
    setContactToDelete(null);
  };

  const handlerDeleteSubmit = async (changedData) => {
    const result = await deleteContacto(changedData);
    if (result) {
      reset();
    }
    if (onSaveChanges) {
      onSaveChanges(result);
    }
  };

  const handlerSelectContactoToEdit = (contacto) => {
    reset();
    clearErrors('descripcion');
    setFocus('descripcion');
    onChangeTipoContacto(contacto.idTipoContacto);
    setValue('activo', contacto?.activo);
    setValue('codigoTipoContacto', contacto?.codigoTipoContacto);
    setValue('confirmado', contacto?.confirmado);
    setValue('descripcion', contacto?.descripcion);
    setValue('descripcionTipoContacto', contacto?.descripcionTipoContacto);
    setValue('fechaConfirmado', contacto?.fechaConfirmado);
    setValue('fechaDesde', contacto?.fechaDesde);
    setValue('fechaHasta', contacto?.fechaHasta);
    setValue('idHistorialContactoPersonaFisica', contacto?.idHistorialContactoPersonaFisica);
    setValue('idPersonaFisica', contacto?.idPersonaFisica);
    setValue('idTipoContacto', contacto?.idTipoContacto);
    setValue('idUsuario', contacto?.idUsuario);
    setValue('publicable', contacto?.publicable);
  };

  return (
    <>
      <Grid>
        <form onSubmit={handleSubmit((changedData) => handlerGuardarSubmit(changedData))}>
          <Grid container spacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={4}>
              <FormControl variant='standard' fullWidth>
                <TipoContactoAutocomplete
                  size='small'
                  // value={getValues("idTipoContacto")}
                  valueTipoContactoId={getValues('idTipoContacto') || null}
                  onChangeOption={(e) => {
                    setValue('idTipoContacto', e?.idTipoContacto, { shouldValidate: true });
                    setValue('codigoTipoContacto', e?.codigo);
                    onChangeTipoContacto(e?.idTipoContacto);
                  }}
                  {...register('idTipoContacto', {
                    validate: (value) => !!value || 'Seleccione un tipo de contacto',
                  })}
                  error={errors.idTipoContacto}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormControl variant='standard' fullWidth>
                <Controller
                  control={control}
                  name='descripcion'
                  rules={validacionContacto}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <TextField
                      onBlur={(e) => onBlur(e)}
                      label='Contacto'
                      variant='standard'
                      fullWidth
                      value={value || ''}
                      onChange={(e) => onChange(e)}
                      error={!!error}
                      helperText={error ? error.message : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 2,
                  justifyContent: 'flex-end',
                  py: 4,
                  pr: { xs: 1, sm: 4 },
                }}
              >
                <Button
                  variant='contained'
                  color='neutral'
                  size='medium'
                  startIcon={<CancelIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    reset();
                    onChangeTipoContacto(null);
                  }}
                  disabled={!isDirty}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='primary'
                  type='submit'
                  startIcon={<SaveIcon />}
                  disabled={!isDirty || !isValid}
                >
                  Guardar cambios
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Box sx={{ background: '#f5f5f5', p: { xs: 1, sm: 2 }, borderRadius: 1 }}>
        {contactosByTipo?.email?.length > 0 && (
          <>
            <ContactoField label='Correo electrónico' sx={{ px: 0 }}>
              <List disablePadding>
                {contactosByTipo?.email?.map((i, k) => (
                  <Grid container key={k} alignItems={'center'}>
                    <Grid item xs={8} sm={9} md={10}>
                      <Typography noWrap fontWeight={'700'}>
                        {i.descripcion}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2}>
                      <Tooltip id='button-link' title='Editar Correo Electrónico'>
                        <IconButton
                          value={i.idHistorialContactoPersonaFisica}
                          aria-label='editar contacto seleccionado.'
                          label='Editar Contacto'
                          onClick={(e) => handlerSelectContactoToEdit(i)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip id='button-link' title='Eliminar Correo Electrónico'>
                        <>
                          {onlyOneContacto && (
                            <IconButton
                              aria-label='No se puede eliminar contacto seleccionado, porque es el único contacto.'
                              label='Eliminar Contacto'
                              onClick={(e) =>
                                showMessage({
                                  status: 'warning',
                                  message:
                                    'No se puede borrar el contacto. Debe tener registrado al menos uno.',
                                })
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                          {!onlyOneContacto && (
                            <IconButton
                              value={i.idHistorialContactoPersonaFisica}
                              aria-label='eliminar contacto seleccionado.'
                              label='Eliminar Contacto'
                              onClick={(e) => handleDeleteContacto(i)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))}
              </List>
            </ContactoField>
            <Divider />
          </>
        )}
        {contactosByTipo?.celular?.length > 0 && (
          <>
            <ContactoField label='Celular' sx={{ px: 0 }}>
              <List disablePadding>
                {contactosByTipo?.celular?.map((i, k) => (
                  <Grid container key={k} alignItems={'center'}>
                    <Grid item xs={8} sm={9} md={10}>
                      <Typography noWrap fontWeight={'700'}>
                        {i.descripcion}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2}>
                      <Tooltip id='button-link' title='Editar Celular'>
                        <IconButton
                          value={i.idHistorialContactoPersonaFisica}
                          aria-label='editar contacto seleccionado.'
                          label='Editar Contacto'
                          onClick={(e) => handlerSelectContactoToEdit(i)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip id='button-link' title='Eliminar Celular'>
                        <>
                          {onlyOneContacto && (
                            <IconButton
                              aria-label='No se puede eliminar contacto seleccionado, porque es el único contacto.'
                              label='Eliminar Contacto'
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                          {!onlyOneContacto && (
                            <IconButton
                              value={i.idHistorialContactoPersonaFisica}
                              aria-label='eliminar contacto seleccionado.'
                              label='Eliminar Contacto'
                              onClick={(e) => handleDeleteContacto(i)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))}
              </List>
            </ContactoField>
            <Divider />
          </>
        )}
        {contactosByTipo?.telefono?.length > 0 && (
          <>
            <ContactoField label='Teléfono' sx={{ px: 0 }}>
              <List disablePadding>
                {contactosByTipo?.telefono?.map((i, k) => (
                  <Grid container key={k} alignItems={'center'}>
                    <Grid item xs={8} sm={9} md={10}>
                      <Typography noWrap fontWeight={'700'}>
                        {i.descripcion}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2}>
                      <Tooltip id='button-link' title='Editar Teléfono'>
                        <IconButton
                          value={i.idHistorialContactoPersonaFisica}
                          aria-label='editar contacto seleccionado.'
                          label='Editar Contacto'
                          onClick={(e) => handlerSelectContactoToEdit(i)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip id='button-link' title='Eliminar Teléfono'>
                        <>
                          {onlyOneContacto && (
                            <IconButton
                              aria-label='No se puede eliminar contacto seleccionado, porque es el único contacto.'
                              label='Eliminar Contacto'
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                          {!onlyOneContacto && (
                            <IconButton
                              value={i.idHistorialContactoPersonaFisica}
                              aria-label='eliminar contacto seleccionado.'
                              label='Eliminar Contacto'
                              onClick={(e) => handleDeleteContacto(i)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))}
              </List>
            </ContactoField>
            <Divider />
          </>
        )}
      </Box>

      <ConfirmacionAccionModal
        id='delete-confirmation'
        title='Eliminar contacto'
        message={messageToDelete || '¿Está seguro de que quiere eliminar el contacto seleccionado?'}
        keepMounted
        open={openDeleteDialog}
        onClose={handleDeleteContactoClose}
        disableScrollLock={true}
      />

      <CustomizedSnackbars
        open={message.message}
        severity={message.status}
        message={message.message}
        onDeleteMessage={clearMessage}
      />
    </>
  );
};
