import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { Button, CardActions, FormControl, Grid, Stack, Alert } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';
import LoadingButton from '../../@iosper/components/LoadingButton';
import CustomizedSnackbars from '../Notifications/SnackBar';
import AfiliadoSelect from '../Afiliados/AfiliadoSelect';
import { consultarOrdenes } from '../../services/Seo/Orden';
import ButtonBack from '../Breadcrumb/ButtonBack';
import DateTextInput from '../Common/DateTextInput';
import ConsumoPrestacionalTable from '../ConsumoPrestacional/ConsumoPrestacionalTable';

const ConsultarOrdenes = () => {
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  const [visibleFind, setVisibleFind] = useState(true);
  const [loading, setLoading] = useState(false);
  const [alertResultFind, setAlertResultFind] = useState('');
  const [ordenes, setOrdenes] = useState(null);
  const [error, setError] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const customStyle = {
    marginBottom: '0px',
    marginTop: '0px',
  };

  const handleVolver = () => {
    setOrdenes([]);
    setVisibleFind(true);
    clean();
  };

  const find = async (data, event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const res = await consultarOrdenes(
        selectedAfiliado?.afiliado.idAfiliado,
        data.fechaDesde,
        data.fechaHasta,
      );
      if (res && res.length > 0) {
        if (res) {
          setOrdenes(res);
          setVisibleFind(false);
          setLoading(false);
        }
      } else {
        setAlertResultFind('No se han encontrado órdenes');
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  /**
   * Evento selección afiliado. Seteamos el nuevo estado de afiliado
   * @param {object} afiliado
   */
  const onSelectAfiliado = (afiliado) => {
    setSelectedAfiliado(afiliado);
  };

  const clean = () => {
    setSelectedAfiliado(null);
    reset();
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
  };

  const handleBorrarMensaje = () => {
    setError('');
  };

  return (
    <div className='container'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonBack marginBotton='1' variant='text' size='small'></ButtonBack>
          <Card sx={{ width: '100%', p: 1 }}>
            <CardContent style={customStyle}>
              <Typography gutterBottom variant='h5' style={customStyle}>
                Consultar órdenes emitidas
              </Typography>
              <Typography gutterBottom style={customStyle}>
                Acá podrás consultar las órdenes emitidas
              </Typography>
            </CardContent>

            {visibleFind && (
              <form>
                <CardContent>
                  <FormControl fullWidth={true} variant='outlined' margin='normal'>
                    <AfiliadoSelect
                      key={keyComponentAfiliado}
                      ref={register('afiliadoRegister', {
                        validate: validateAfiliado,
                      })}
                      onSelectAfiliado={onSelectAfiliado}
                    />
                    {errors.afiliadoRegister && (
                      <p style={{ color: 'red' }}>Debe seleccionar el afiliado</p>
                    )}
                  </FormControl>

                  <FormControl fullWidth={true} variant='outlined' margin='normal'>
                    <DateTextInput
                      register={register}
                      errors={setError}
                      required={true}
                      label='Desde'
                      fieldName='fechaDesde'
                    />
                    {errors['fechaDesde'] && (
                      <p style={{ color: 'red' }}>{errors['fechaDesde'].message}</p>
                    )}
                  </FormControl>
                  <FormControl fullWidth={true} variant='outlined' margin='normal'>
                    <DateTextInput
                      register={register}
                      errors={setError}
                      required={true}
                      label='Hasta'
                      fieldName='fechaHasta'
                    />
                    {errors['fechaHasta'] && (
                      <p style={{ color: 'red' }}>{errors['fechaHasta'].message}</p>
                    )}
                  </FormControl>
                </CardContent>

                <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2 }}
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    alignItems={{ xs: 'strech' }}
                  >
                    <Button
                      variant='contained'
                      size='medium'
                      startIcon={<HighlightOff />}
                      color='neutral'
                      onClick={clean}
                    >
                      Limpiar
                    </Button>
                    <LoadingButton
                      fullWidth={true}
                      type='submit'
                      variant='contained'
                      color='primary'
                      loading={loading ? 'show' : 'hide'}
                      content={'Buscar'}
                      startIcon={<SearchIcon />}
                      onClick={handleSubmit(find)}
                    />
                  </Stack>
                </CardActions>
              </form>
            )}
            {!visibleFind && (
              <Grid container direction='column' justifyContent='space-between' spacing={1}>
                <Grid item xs={12} sm={8}>
                  <Alert icon={false} severity='info'>
                    <b style={{ color: '#1d1e1eb0' }}>
                      {selectedAfiliado?.personaFisica?.apellido},{' '}
                      {selectedAfiliado?.personaFisica?.nombre} (
                      {selectedAfiliado?.personaFisica?.numeroDocumentoString})
                    </b>
                  </Alert>
                </Grid>
                <Grid item>
                  <ConsumoPrestacionalTable consumos={ordenes} columnasExcluidas={['importe']} />
                </Grid>
                <Grid item>
                  <Grid container justifyContent='flex-end' spacing={1}>
                    <Grid item>
                      {' '}
                      <Button
                        variant='contained'
                        color='neutral'
                        startIcon={<SearchIcon />}
                        onClick={() => handleVolver()}
                      >
                        Buscar Otros
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
        <CustomizedSnackbars
          open={alertResultFind}
          severity='info'
          message={alertResultFind}
          onDeleteMessage={handleBorrarMensaje}
        />
      </Grid>
    </div>
  );
};

export default ConsultarOrdenes;
