import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';
import { useInfoAfiliado } from '../Afiliados/InfoAfiliadoProvider';
import ordenImg from '../../assets/images/menu/orden.svg';
import grupoFamiliarImg from '../../assets/images/menu/grupo-familiar.svg';
import OrdenDigitalImg from '../../assets/images/svg/mobile-qrScan.svg';
import { useTheme } from '@emotion/react';
import CardButton from '../../@iosper/components/Card/CardButton';
import CardButtonProfile from '../../@iosper/components/Card/CardButtonProfile';
import Title from '../../@iosper/components/Page/Title';
import { isMobile } from '../../@iosper/utils/DeviceDetector';

const Home = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const showOrdenDigital = isMobile() || !matches;
  const { infoAfiliado } = useInfoAfiliado();

  return (
    <>
      <Title autoFocus={false}>Inicio</Title>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <CardButtonProfile
            title={`${infoAfiliado?.personaFisica?.apellido}, ${infoAfiliado?.personaFisica?.nombre}`}
            description={'Gestioná tu perfil, datos de contacto y seguridad.'}
            to={'/perfil'}
            image={''}
          />
        </Grid>

        {showOrdenDigital && (
          <Grid item xs={12} sm={6} lg={4}>
            <CardButton
              title={'Orden Electrónica'}
              description={'Generá ordenes electrónicas de consulta.'}
              to={'/read-orden'}
              image={OrdenDigitalImg}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6} lg={4}>
          <CardButton
            title={'Orden Impresa'}
            description={'Generá ordenes de consulta.'}
            to={'/emitir-orden'}
            image={ordenImg}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <CardButton
            title={'Grupo Familiar'}
            description={'Consultá los datos del grupo familiar.'}
            to={'/grupo-familiar'}
            image={grupoFamiliarImg}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
