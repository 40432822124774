import { TextField } from '@mui/material';

const NombrePropioTextInput = ({
  fieldName,
  label,
  register,
  errors,
  required,
  variant,
  maxLength,
  minLength,
}) => {
  return (
    <div>
      <TextField
        variant={variant}
        fullWidth
        name={fieldName}
        label={label}
        type='text'
        id={fieldName}
        {...register(fieldName, {
          required: {
            value: required,
            message: 'Debe ingresar ' + label,
          },
          maxLength: {
            value: maxLength,
            message: label + ' debe tener como máximo ' + maxLength + ' caracteres.',
          },
          minLength: {
            value: minLength,
            message: label + ' debe tener como mínimo ' + minLength + ' caracteres.',
          },
          pattern: {
            value: /^(([a-záéíóúñA-ZÁÉÍÓÚÑ ]{2,60}))$/,
            message: 'El formato no es correcto.',
          },
        })}
      />
      {errors[fieldName] && <p style={{ color: 'red' }}>{errors[fieldName].message}</p>}
    </div>
  );
};

NombrePropioTextInput.defaultProps = {
  maxLength: 60,
  minLength: 2,
  variant: 'standard',
};
export default NombrePropioTextInput;
