import React, { useEffect, useState } from 'react';
import {
  ToggleButton,
  Collapse,
  List,
  ListItem,
  Box,
  Card,
  Alert,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { buscarAportes } from '../../services/Busquedas/Aportes';
import { useInfoAfiliado } from '../Afiliados/InfoAfiliadoProvider';
import { useForm } from 'react-hook-form';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { useWidth } from '../../@iosper/utils/UseWidth';

const BuscarAportes = () => {
  const { infoAfiliado } = useInfoAfiliado();
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [alertResultFind, setAlertResultFind] = useState('');
  const [error, setError] = useState('');
  const [aportes, setAportes] = useState([]);
  const [visibleFind, setVisibleFind] = useState(false);
  const screenWidth = useWidth();

  const { reset } = useForm({ mode: 'onBlur' });

  const clean = () => {
    reset();
    setVisibleInfo(false);
  };

  const DataCollapseDrawer = (props) => {
    const { aporte } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <Box sx={{ width: '100%' }}>
        <ToggleButton
          aria-label='expand row'
          value='check'
          onChange={() => {
            setOpen(!open);
          }}
          sx={{ border: 0 }}
          fullWidth={true}
        >
          <Grid
            container
            sx={{
              marginRight: '-30px',
              marginLeft: '-15px',
              marginBottom: '-8px',
              marginTop: '-8px',
            }}
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={2}
            flexWrap='nowrap'
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            <Typography
              variant='body1'
              align='left'
              gutterBottom={false}
              sx={{ color: '#000', textTransform: 'none' }}
            >
              {`${aporte.mes + ' - ' + aporte.reparticion || ''}`}
            </Typography>
          </Grid>
        </ToggleButton>

        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <List sx={{ py: -5, px: 0, marginBottom: '-10px', marginTop: '-10px' }}>
              <ListItem sx={{ py: 0, gap: 1, px: -2 }}>
                <Typography>{`Tipo:`}</Typography>
                <Typography sx={{ fontWeight: 'bold' }}>{`${
                  aporte?.tipoPresentacion || ''
                }`}</Typography>
              </ListItem>
              <ListItem sx={{ py: 0, gap: 1 }}>
                <Typography>{`Aporte Personal:`}</Typography>
                <Typography sx={{ fontWeight: 'bold' }}>{`${aporte?.aporte || ''}`}</Typography>
              </ListItem>
              {aporte?.aportesPositivos?.map((i, k) => (
                <ListItem key={`aporte-positivo-${k}`} sx={{ py: 0, gap: 1 }}>
                  <Typography>{`${i.descripcion || ''}:`}</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>{`$ ${i.importe || ''}`}</Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Collapse>
      </Box>
    );
  };

  /// -------------------- DATA GRID APORTES --------------------------
  const mapAporteToRow = (p) => ({
    id: p.orden,
    mes: p.mes,
    tipoPresentacion: p.tipoPresentacion,
    reparticion: p.descripcionReparticion ? p.descripcionReparticion : 'No encontrada',
    aporte: `$  ${p.aportePersonal || '0'}`,
    aporteSecundario: `$  ${p.adherenteSecundario || '0'}`,
    aportePatronal: `$  ${p.aportePatronal || '0'}`,
    descuentoSubsidio: `$  ${p.descuentoSubsidio || '0'}`,
    creditoAsistencial: `$  ${p.creditoAsistencial || '0'}`,
    hijoMenor: `$  ${p.hijoMenorDe35 || '0'}`,
    menorCargo: `$  ${p.menoraCargo || '0'}`,
    aportesPositivos: p.aportesPositivos,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: (
        <div>
          <Typography component='div' align='justify' sx={{ fontSize: '14px' }}>
            Esta información es recibida desde
          </Typography>
          <Typography component='div' align='justify' sx={{ fontSize: '14px' }}>
            las reparticiones liquidadoras de sueldo.
          </Typography>
        </div>
      ),
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer aporte={a.row} />,
    },
    { field: 'id', headerName: 'Nº', width: 5 },
    { field: 'mes', headerName: 'Mes', width: 80 },
    { field: 'tipoPresentacion', headerName: 'Tipo Presentación', width: 150 },
    { field: 'reparticion', headerName: 'Repartición', width: 190 },
    { field: 'aporte', headerName: 'Ap. Personal', flex: 0.1 },
    { field: 'descuentoSubsidio', headerName: 'F. Voluntario', flex: 0.1 },
    { field: 'creditoAsistencial', headerName: 'Crédito', flex: 0.1 },
    { field: 'aporteSecundario', headerName: 'Ad. Sec.', flex: 0.1 },
    { field: 'hijoMenor', headerName: 'Hijo Menor', flex: 0.1 },
    { field: 'menorCargo', headerName: 'Menor a Cargo', flex: 0.1 },
  ];

  useEffect(() => {
    const get = async () => {
      const res = await buscarAportes({
        dniAfiliado: infoAfiliado.personaFisica.numeroDocumento,
        meses: 6,
      });
      if (res && res.length > 0) {
        if (res.length >= 50) {
          setVisibleInfo(true);
        }
        setVisibleFind(true);
        setAportes(res);
      } else {
        setAlertResultFind('No se han encontrado aportes');
        clean();
      }
    };
    get();
  }, []);

  const handleBorrarMensaje = () => {
    setError('');
    setAlertResultFind('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography
              tabIndex={0}
              aria-label='Mis Aportes'
              gutterBottom
              variant='h5'
              component='h2'
            >
              Mis Aportes
            </Typography>
            <Typography variant='string' component={'div'}>
              Acá podrás ver en detalle los aportes realizados a la obra social en los últimos 6
              meses.
            </Typography>

            <Grid container direction='column' justifyContent='space-between'>
              <Grid item>
                <Alert icon={false} severity='info'>
                  <b style={{ color: '#1d1e1eb0' }}>
                    {infoAfiliado?.personaFisica?.apellido}, {infoAfiliado?.personaFisica?.nombre} (
                    {infoAfiliado?.personaFisica?.numeroDocumentoString})
                  </b>
                </Alert>
              </Grid>
            </Grid>

            {visibleFind && (
              <Grid container direction='column' justifyContent='space-between' spacing={1}>
                <Grid item>
                  <div style={{ height: '60vh', width: '100%' }}>
                    <DataGrid
                      sx={{
                        border: 0,
                        '& .MuiDataGrid-cell': {
                          py: '8px',
                        },
                      }}
                      loading={!aportes.length}
                      rows={aportes.map((a) => mapAporteToRow(a))}
                      columns={columns}
                      getRowHeight={() => 'auto'}
                      getEstimatedRowHeight={() => 200}
                      columnVisibilityModel={{
                        desplegable: screenWidth === 'xs',
                        id: false,
                        mes: screenWidth !== 'xs',
                        reparticion: screenWidth !== 'xs',
                        aporte: screenWidth !== 'xs',
                        tipoPresentacion: screenWidth !== 'xs',
                        descuentoSubsidio: screenWidth !== 'xs',
                        creditoAsistencial: screenWidth !== 'xs',
                        aporteSecundario: screenWidth !== 'xs',
                        hijoMenor: screenWidth !== 'xs',
                        menorCargo: screenWidth !== 'xs',
                      }}
                      pagination
                      autoPageSize
                      disableColumnMenu
                    />
                  </div>
                </Grid>
                {visibleInfo ? (
                  <Grid item>
                    <Alert severity='info'>Sólo se muestran los primeros 50 resultados.</Alert>
                  </Grid>
                ) : null}
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default BuscarAportes;
