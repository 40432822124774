import { Box, Grid, Paper, Typography } from '@mui/material';

export const NotificacionItem = ({ idSolicitud, tipoNotificacion, numeroNotificacion }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: '#e3ecf7',
      }}
    >
      <Grid containerspacing={2}>
        <Grid item xs={12} sm container wrap='nowrap'>
          <Grid item xs container direction='column' spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant='subtitle1' component='div'>
                <Box sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                  {tipoNotificacion.toLowerCase()}
                </Box>
              </Typography>
              <Typography variant='body2' gutterBottom></Typography>
              <Typography variant='body2' color='text.secondary' style={{ textWrap: 'wrap' }}>
                Notificación de la solicitud #{idSolicitud}.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
